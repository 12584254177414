@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .product-cart-view {
    @apply py-2 bg-white rounded-lg flex h-full lg:rounded-xl cursor-pointer mb-2
  }

  .product-cart-view:hover {
    @apply hover:shadow-2xl;
  }

  .product-cart-list {
    @apply  bg-white rounded-lg flex box-shadow cursor-pointer mb-2  lg:rounded-2xl
  }

  .product-cart-list:hover {
    @apply hover:shadow-2xl ;
  }

  .checkout-btn {
    @apply w-full flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary-dark
  }
  
  .shop-now-btn {
    @apply w-full flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary-dark
  }
}


body { font-family: 'Work Sans', sans-serif;}

.hide-scrollbar-custom::-webkit-scrollbar { width: 0 !important }
.h-128 { height: 32rem }
.header {background: #323643; box-shadow: 18.2143px 18.2143px 36.4286px rgba(211, 209, 216, 0.25)}
.store-details-wrap  .store-logo { height:60px; margin:0 8px 0 0; object-fit:contain; background: #fff;}
.store-details-wrap  .store-open { background: #0A8A2D; box-shadow: 0px 4px 4px rgba(10, 138, 45, 0.2); border-radius: 11px; margin-left: 7px;}
.store-details-wrap h1 {font-size: 18px; text-transform: uppercase; color: #fff; font-weight: 600;}
.store-details-wrap .address-text {font-size:11px}
.box-shadow {box-shadow: 18.2143px 18.2143px 36.4286px rgba(211, 209, 216, 0.25); }
.discound-label{ background: #0007; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2); border-radius: 30px; padding:2px 10px; font-size:14px; color: #fff;}
.swiper-button-prev, .swiper-button-next  { color: #323643 !important; }
.swiper-button-next {right:0 !important}
.swiper-button-prev {left:0 !important}
.swiper-button-next:after, .swiper-button-prev:after { font-size:1.5rem !important; background:#fff;  padding:12px; border-radius:5px }
.swiper-pagination-bullet-active {background:#fff !important; }
.desk-min-height {
  min-height: 560px;
}
@media only screen and (max-width: 600px) {
    .swiper-button-next:after, .swiper-button-prev:after { font-size:1rem !important; padding:6px; }
    .store-details-wrap  .store-logo { height:55px}
    .desk-min-height {
      height: auto;
    }
}

