.root {
  @apply fixed flex bg-white items-center inset-0 z-50 justify-center;
  background-color: rgba(0, 0, 0, 0.45);
}

.modal {
  @apply p-6 border bg-white  rounded-lg;
}

.modal:focus {
  @apply outline-none;
}
